import React, { useState, useEffect, useRef } from "react";
import SimpleKeyboard from "react-simple-keyboard";
import _toNumber from "lodash/toNumber";
import _get from "lodash/get";
import { ModalHeader, openTouchInput, closeTouchInput } from "./index";
import translateText from "../translate";

const BUTTON_THEME = [
  {
    class: "keyboard-space",
    buttons: "{space}",
  },
  {
    class: "keyboard-enter",
    buttons: "{enter}",
  },
];

function buildShiftHtml(isCapsLock) {
  return `
    <span style="display: flex; align-items: center;">
      <span style="${isCapsLock ? "border-bottom: 5px solid;" : "margin-top: -5px;"} margin-left: -10px; margin-right: 10px;">
        &uarr;
      </span> ${translateText("shift")}
    </span>
  `;
}

export default function Keyboard(props) {
  const keyboardRef = useRef();

  const { inputName, defaultValue, element, type, keyboardLayout, units, onBack, system_info, isOpen, showValue, config } = props;
  let value = (defaultValue || {}).value;
  if (typeof value === "number") value = value.toString();
  const defaultLayoutName = system_info.language || "en";
  const keyboardWidth = type === "numeric" ? 100 : system_info.keyboard_width_percent || 100;
  const [layoutName, setLayoutName] = useState(defaultLayoutName);
  const [unit, setUnit] = useState("");
  const [shiftPressCount, setShiftPressCount] = useState(0);
  const [shiftDisplay, setShiftDisplay] = useState(buildShiftHtml());
  const [leftValue, setLeftValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const keyboardDisplay = {
    "{enter}": translateText("enter"),
    "{bksp}": translateText("delete"),
    "{space}": translateText("space"),
    "{123}": "123",
    "{abc}": "abc",
    "{empty}": " ",
    "{larr}": "&larr;",
    "{rarr}": "&rarr;",
    "{shift}": shiftDisplay,
  };

  useEffect(() => {
    setShiftPressCount(0);
    setShiftDisplay(buildShiftHtml());
  }, [isOpen]);

  // whenever opening a new touchinput Keyboard...
  useEffect(() => {
    if (showValue) setLeftValue("");
    if (!element) return;
    setShiftPressCount(0);
    setShiftDisplay(buildShiftHtml());
    // set the keyboard's internal value and inputName
    keyboardRef.current.setInput(showValue ? "" : value, inputName);

    // set the layout type
    if (type === "numeric") setLayoutName("numeric");
    else setLayoutName(defaultLayoutName);

    // sync the caret position
    keyboardRef.current.setCaretPosition(element.selectionStart || (value || "").length);

    // pass initial state for "unit"
    setUnit(props.unit);
  }, [element]);

  // sync caret position on layout change
  useEffect(() => {
    if (element && !isNaN(element.selectionEnd)) {
      keyboardRef.current.setCaretPosition(element.selectionEnd);
    }
  }, [layoutName]);

  Keyboard.clearInput = function () {
    keyboardRef.current.clearInput();
  };

  // caretDelta is used to keep track of caret displacement
  let caretDelta;

  const onKeyPress = (button) => {
    const input = element;
    if (input) input.focus();

    caretDelta = button.length;

    if (button === "{enter}") {
      if (props.onPressEnter) {
        const str = keyboardRef.current.getInput() || value;
        let msg = "";
        if (type === "numeric") {
          const validations = _get(config, "validations", []);
          for (const v in validations) {
            const { min, max, err_msg } = validations[v];
            const val = _toNumber(str);
            if (val > max || val < min) {
              msg = err_msg;
              break;
            }
          }
        }
        setErrorMsg(msg);
        if (!msg) props.onPressEnter(str);
      } else {
        closeTouchInput();
        return;
      }
    } else if (button === "{shift}") {
      if (shiftPressCount === 0) {
        setShiftPressCount(1);
        setLayoutName(`${layoutName}_shift`);
      } else if (shiftPressCount === 1) {
        setShiftPressCount(2);
        setShiftDisplay(buildShiftHtml(true));
      } else if (shiftPressCount === 2) {
        setShiftPressCount(0);
        setShiftDisplay(buildShiftHtml());
        setLayoutName(layoutName.split("_")[0]);
      }
      return;
    } else if (button === "{123}") {
      setLayoutName("text_numeric");
      return;
    } else if (button === "{abc}") {
      setLayoutName(defaultLayoutName);
      setShiftPressCount(0);
      setShiftDisplay(buildShiftHtml());
      return;
    } else if (button === "{larr}") {
      // do nothing if already at leftmost caret position
      if (input.selectionStart === 0) return;

      const nextCaretPos = input.selectionStart - 1;
      input.selectionStart = input.selectionEnd = nextCaretPos;
      keyboardRef.current.setCaretPosition(nextCaretPos);

      // ensure caret is in view
      input.blur();
      input.focus();
      return;
    } else if (button === "{rarr}") {
      // do nothing if already at rightmost caret position
      const str = keyboardRef.current.getInput();
      if (input.selectionStart === str.length) return;

      const nextCaretPos = input.selectionStart + 1;
      input.selectionStart = input.selectionEnd = nextCaretPos;
      keyboardRef.current.setCaretPosition(nextCaretPos);

      // ensure caret is in view
      input.blur();
      input.focus();
      return;
    } else if (button === "{bksp}") {
      // do nothing if already at leftmode caret position
      if (input.selectionStart === 0) return;
      caretDelta = -1;
    }
  };

  const onChange = (text) => {
    const input = element;

    // ensure value is a number
    if (type === "numeric" && isNaN(_toNumber(text)) && text !== "-") {
      keyboardRef.current.setInput(text.slice(0, -1));
      keyboardRef.current.setCaretPosition(input.selectionStart);
      return; // do nothing if not a number
    }

    // apply caretDelta to caret position
    let nextCaretPos = input.selectionStart + caretDelta;

    if (props.onChange) props.onChange(text, unit);
    if (showValue) setLeftValue(text);
    // apply update to caret position after setting input value
    input.selectionStart = input.selectionEnd = nextCaretPos;

    // ensure caret is in view
    input.blur();
    input.focus();

    if (layoutName.includes("_shift") && shiftPressCount === 1) {
      setLayoutName(layoutName.split("_")[0]);
      setShiftDisplay(buildShiftHtml());
      setShiftPressCount(0);
    }
  };

  const contentStyle = { ...styles.keyboardContentStyle, width: `${keyboardWidth}%` };

  return (
    <>
      <ModalHeader
        id="keyboard"
        onDelete={() => {
          if (props.onChange) props.onChange("");
          keyboardRef.current.clearInput();
          element.focus();
        }}
        title={translateText(inputName)}
        onBack={onBack ? () => onBack(keyboardRef.current.getInput()) : null}
        uniqueId={props.uniqueId}
        useTouchInputNav={props.useTouchInputNav}
      />
      {errorMsg && <div style={{ marginBottom: -18, marginTop: 18 }}>{translateText(errorMsg)}</div>}
      <div style={contentStyle}>
        {showValue && (
          <div style={styles.leftValueContainer}>
            <div style={styles.leftValue}>
              <div>{translateText(inputName)}</div>
              <div>{leftValue || value || "N/A"}</div>
              <div>{(units || [])[0]}</div>
            </div>
          </div>
        )}
        <div style={{ maxWidth: type === "numeric" ? "750px" : null, width: "100%" }}>
          <SimpleKeyboard
            inputName={inputName}
            preventMouseDownDefault
            preventMouseUpDefault
            disableButtonHold
            layoutName={layoutName}
            buttonTheme={BUTTON_THEME}
            layout={keyboardLayout}
            display={keyboardDisplay}
            keyboardRef={(r) => (keyboardRef.current = r)}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </div>
        {renderUnitSelect()}
      </div>
    </>
  );

  function renderUnitSelect() {
    if (!units) return;
    return (
      <div style={styles.unitSelectWrapper}>
        <div style={{ overflowY: "scroll", height: 90 * 4 + 70 }}>
          {units.map((u) => {
            const style = { ...styles.unitButton };
            let isActive = unit === u;
            if (u === "custom" && !units.includes(unit)) isActive = true;
            if (isActive) style.backgroundColor = "var(--secondary)";
            return (
              <div
                style={style}
                key={u}
                onMouseDown={(e) => {
                  e.preventDefault();
                  // custom unit select
                  if (u === "custom") {
                    const str = keyboardRef.current.getInput();
                    keyboardRef.current.clearInput();
                    setLayoutName(defaultLayoutName);
                    element.selectionStart = element.selectionEnd = -1;
                    return openTouchInput({
                      element: props.element,
                      ignoreCaretPos: true,
                      inputName: props.inputName,
                      onChange: (e) => {
                        if (props.onChange) props.onChange(str, e);
                      },
                      defaultValue: "",
                      type: "text",
                    });
                  }

                  setUnit(u);
                  const str = keyboardRef.current.getInput();
                  if (props.onChange) props.onChange(str, u);

                  const caretPos = element.selectionStart;

                  // persist caret position
                  setTimeout(() => {
                    element.selectionStart = element.selectionEnd = caretPos;
                  }, 10);
                }}
              >
                {translateText(u, true)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
const styles = {
  keyboardContentStyle: {
    justifyContent: "center",
    paddingTop: "1.5rem",
    display: "flex",
    margin: "auto",
  },
  unitSelectWrapper: {
    marginTop: 5,
    marginBottom: 20,
    marginLeft: 80,
  },
  unitButton: {
    height: 70,
    marginBottom: 20,
    backgroundColor: "rgba(255,255,255,.3)",
    width: 200,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: ".1s",
  },
  leftValue: {
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 60,
    flexDirection: "column",
    lineHeight: 1.4,
    backgroundColor: "var(--grey)",
    minWidth: 320,
    minHeight: 320,
    paddingRight: 40,
    paddingLeft: 40,
    borderRadius: 20,
  },
  leftValueContainer: {
    width: 750,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

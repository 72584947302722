import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "./button";
import translateText from "./translate";

function ConfirmationModal(props) {
  const { id, isOpen, close, title, body, submitText, handleConfirm, size = "md" } = props;
  const [inProgress, setInProgress] = useState(false);

  function submit() {
    setInProgress(true);
    handleConfirm();
  }

  useEffect(() => {
    if (isOpen) {
      setInProgress(false);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={() => close()} size={size} backdrop="static">
      <ModalHeader toggle={() => !inProgress && close()}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        {close &&
          (<Button id={`${id}_cancel`} color="secondary" onClick={() => close()} disabled={inProgress}>
            {translateText("cancel")}
          </Button>
        )}
        <Button id={`${id}_submit`} color="primary" onClick={() => submit()} disabled={inProgress} waiting={inProgress}>
          {submitText || translateText("yes")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  handleConfirm: PropTypes.func,
  size: PropTypes.string,
};

export default ConfirmationModal;

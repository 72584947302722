import React, { useState, useEffect } from "react";
import _isNumber from "lodash/isNumber";
import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import { ModalHeader, openTouchInput } from "./index";
import moment from "moment";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { isDateTimeValid } from "../../utils/validations";
import translateText from "../translate";

export default function Datetime(props) {
  const { defaultValue, inputName, element, type, config, allData } = props;
  const { disable_minutes, default_minutes } = config || {};
  const [date, setDate] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    setErrorMsg(null);
    if (!defaultValue || !defaultValue.value) {
      const today = new Date();
      if (_isNumber(default_minutes)) {
        today.setMinutes(default_minutes);
      }
      setDate(today);
    } else setDate(defaultValue.value);
  }, [defaultValue]);

  const m = moment(date);

  return (
    <div>
      <ModalHeader
        id="datetime_picker"
        onDelete={() => {
          props.onChange(null);
          setDate(new Date());
        }}
        title={translateText(inputName)}
        uniqueId={props.uniqueId}
        useTouchInputNav={props.useTouchInputNav}
      />
      <div className="mt-4" style={styles.selectionWrapper}>
        {errorMsg && <div className="mb-3">{translateText(errorMsg)}</div>}
        <div style={{ display: "flex", alignItems: "center" }}>
          <TimeInputSelector
            label="Year"
            id="year_selector"
            value={date}
            width={250}
            onIncrement={() => setDate(m.add(1, "year").toDate())}
            onDecrement={() => setDate(m.subtract(1, "year").toDate())}
            renderValue={(x) => moment(x).format("YYYY")}
            onClick={() => onClick("year")}
          />
          <TimeInputSelector
            label="Month"
            id="month_selector"
            value={date}
            width={250}
            onIncrement={() => setDate(m.add(1, "month").toDate())}
            onDecrement={() => setDate(m.subtract(1, "month").toDate())}
            renderValue={(x) => translateText(moment(x).format("MMM"))}
            onClick={() => onClick("month")}
          />
          <TimeInputSelector
            label="Day"
            id="date_selector"
            value={date}
            width={200}
            onIncrement={() => setDate(m.add(1, "day").toDate())}
            onDecrement={() => setDate(m.subtract(1, "day").toDate())}
            renderValue={(x) => moment(x).format("DD")}
            onClick={() => onClick("day")}
          />
          {type === "timestamp" && (
            <>
              <div style={{ width: 40 }} />
              <TimeInputSelector
                label="Hour"
                id="hour_selector"
                value={date}
                width={200}
                onIncrement={() => setDate(m.add(1, "hour").toDate())}
                onDecrement={() => setDate(m.subtract(1, "hour").toDate())}
                renderValue={(x) => moment(x).format("HH")}
                onClick={() => onClick("hour")}
              />
              <TimeInputSelector
                label="Minute"
                id="minute_selector"
                value={date}
                width={200}
                onIncrement={() => setDate(m.add(1, "minute").toDate())}
                onDecrement={() => setDate(m.subtract(1, "minute").toDate())}
                renderValue={(x) => moment(x).format("mm")}
                onClick={() => onClick("minute")}
                isDisabled={disable_minutes}
              />
            </>
          )}
          <div style={{ width: 40 }} />
          <div style={styles.saveButton} onClick={onSubmit}>
            {translateText("save")}
          </div>
        </div>
      </div>
    </div>
  );

  function onSubmit() {
    if (moment(date).isAfter(moment())) {
      setErrorMsg("date_cannot_be_in_the_future");
      return;
    }
    const validations = _get(config, "validations", []);
    for (const v in validations) {
      const { op, field, err_msg } = validations[v];
      const fieldValue = _get(allData, [field, "value"], null);
      if (date && fieldValue && !isDateTimeValid(date, op, fieldValue)) {
        setErrorMsg(err_msg);
        return;
      }
    }
    setErrorMsg(null);
    props.onChange(date);
  }

  function onClick(interval) {
    if (interval === "month") {
      return openTouchInput({
        element,
        inputName: props.inputName,
        defaultValue: { value: `${moment(date).month()}` },
        type: "select",
        list: [
          { label: translateText("january"), value: "0" },
          { label: translateText("february"), value: "1" },
          { label: translateText("march"), value: "2" },
          { label: translateText("april"), value: "3" },
          { label: translateText("may"), value: "4" },
          { label: translateText("june"), value: "5" },
          { label: translateText("july"), value: "6" },
          { label: translateText("august"), value: "7" },
          { label: translateText("september"), value: "8" },
          { label: translateText("october"), value: "9" },
          { label: translateText("november"), value: "10" },
          { label: translateText("december"), value: "11" },
        ],
        onChange: (e) => {
          const t = moment(date);
          t.set(interval, e.value);
          const update = t.toDate();
          setDate(update);
          return openTouchInput({
            element,
            inputName: props.inputName,
            onChange: props.onChange,
            defaultValue: { value: update, label: update },
            type,
            config,
            useTouchInputNav: props.useTouchInputNav,
          });
        },
        config,
        useTouchInputNav: props.useTouchInputNav,
      });
    }
    let defaultValue = { value: "" };
    const validations = [];
    if (interval === "day") {
      defaultValue.value = moment(date).day() + 1;
      validations.push({
        min: 1,
        max: 31,
        err_msg: "Day must be between 1 and 31",
      });
    } else if (interval === "year") {
      defaultValue.value = moment(date).year();
      const curYear = moment().year();
      validations.push({
        min: 1900,
        max: curYear,
        err_msg: `Year must be between 1900 and ${curYear}`,
      });
    } else if (interval === "hour") {
      defaultValue.value = moment(date).hour();
      validations.push({
        min: 0,
        max: 24,
        err_msg: "Hour must be between 0 and 24",
      });
    } else if (interval === "minute") {
      defaultValue.value = moment(date).minute();
      validations.push({
        min: 0,
        max: 60,
        err_msg: "Minute must be between 0 and 60",
      });
    }
    return openTouchInput({
      element,
      inputName: interval,
      defaultValue,
      type: "numeric",
      useTouchInputNav: props.useTouchInputNav,
      showValue: true,
      config: { validations },
      onPressEnter: (e) => {
        const currentDate = moment(date);
        currentDate.set(interval === "day" ? "date" : interval, e);
        const update = currentDate.toDate();
        setDate(update);
        return openTouchInput({
          element,
          inputName: props.inputName,
          onChange: props.onChange,
          defaultValue: { value: update, label: update },
          type,
          config,
          useTouchInputNav: props.useTouchInputNav,
        });
      },
    });
  }
}

function TimeInputSelector(props) {
  const { id, onClick, renderValue = (x) => x, width, value, onIncrement, onDecrement, isDisabled, label } = props;
  const caretStyle = _cloneDeep(styles.caretIcon);
  if (isDisabled) {
    caretStyle.visibility = "hidden";
  }
  return (
    <div style={{ ...styles.selector, width }}>
      <div style={{ marginBottom: -26 }}>{translateText(label)}</div>
      <FaCaretUp id={`${id}_caret_up`} onClick={() => !isDisabled && onIncrement()} style={caretStyle} />
      <div
        id={`${id}_custom_input`}
        className="btn btn-secondary"
        style={{ ...styles.selectedItem, width }}
        onClick={() => {
          if (!isDisabled) onClick();
        }}
      >
        {value ? renderValue(value) : ""}
      </div>
      <FaCaretDown id={`${id}_caret_down`} onClick={() => !isDisabled && onDecrement()} style={caretStyle} />
    </div>
  );
}

const styles = {
  selector: {
    marginLeft: 10,
    marginRight: 10,
  },
  selectedItem: {
    padding: 8,
    height: 140,
    fontSize: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  caretIcon: {
    fontSize: 80,
    marginTop: 20,
    marginBottom: 20,
  },
  saveButton: {
    backgroundColor: "var(--secondary)",
    height: 140,
    padding: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 40,
    width: 200,
    borderRadius: 10,
  },
  selectionWrapper: {
    height: "420px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    overflow: "auto",
  },
};
